:root {
  --pbpadding: 3em;
}

:root {
  --base-font-size: 20px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-size: var(--base-font-size);
  font-family: "poppins-reg", sans-serif, Arial;
  font-variation-settings: "wght" 400;
  background-color: white;
  position: relative;
}

p {
  line-height: 1.75;
  margin-bottom: 1.25em;
}

strong,
b {
  font-variation-settings: "wght" 600;
}

img {
  height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
  margin-top: 1rem;
}

p + ul {
  margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
  color: #000;
  text-decoration: none;
}
a:not(.nav-link, .skiplink, .reset-link):hover, .link:hover {
  text-decoration: underline;
}

article.text-side a, article a {
  text-underline-offset: 0.3em;
}

.reset-link {
  text-decoration: none !important;
  color: black !important;
}
.reset-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}

:is(.backto.backto) {
  text-decoration: none;
  opacity: 0.6;
  margin-top: 2rem;
  display: inline-block;
}
:is(.backto.backto) img {
  width: 27px;
  margin-right: 5px;
}
:is(.backto.backto):hover {
  text-decoration: underline;
}

.mysuperflexteststyle {
  background-color: red;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

div.objectfit {
  height: 100%;
}
div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0;
}

.skiplink {
  position: absolute;
  left: -9999em;
}
.skiplink:focus {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 2rem;
  z-index: 1000;
  background: #000000;
  padding: 1rem 1.8rem;
  border: 4px solid white;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.centred {
  text-align: center;
}

.align-right {
  text-align: right;
}

.gorel {
  position: relative;
}

.rounded-box {
  border-radius: 69px;
  overflow: hidden;
}
.rounded-box__square-bottom-left {
  border-radius: 69px 69px 69px 0;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue {
  background-color: #452775;
  color: #FFF;
}

.bg-grey {
  background-color: #666;
}

.bg-greylt {
  background-color: #E5E5E5;
}

.bg-sky {
  background-color: #5DABBF;
}

.gowhite {
  color: #FFF;
}

.goblue {
  color: #452775;
}

.bordered-blue {
  border: 0.5px solid #452775;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.pb-logo-carousel .carousel-title {
  margin-bottom: 1.75em;
  font-family: "poppins-light", sans-serif;
}
.pb-logo-carousel .logo-carousel .slide-item {
  height: 75px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pb-logo-carousel .logo-carousel .slide-item a {
  height: auto;
  min-height: 0;
  display: block;
}
.pb-logo-carousel .logo-carousel .slide-item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 50px;
  display: block;
}