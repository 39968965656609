@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-logo-carousel {
    .carousel-title {
        margin-bottom: 1.75em;
        font-family: $font-light;
    }
    .logo-carousel {
        .slide-item {
            height: 75px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                height: auto;
                min-height: 0;
                display: block;
            }
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 50px;
                display: block;
            }
        }
    }
    @include bpr-media-breakpoint(down, md) {

	}
}